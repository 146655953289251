<template>
    <section class="info-2">
        <div class="container py-5">
            <h1 class="display-4 font-weight-bold text-center mb-5 title"
                data-aos="fade-up"
                data-aos-duration="1000">
                人體自身擁有玻尿酸
            </h1>
            <h3 class="text-justify content"
                data-aos="fade"
                data-aos-duration="1000">
                玻尿酸原本就存在於皮膚內，能夠幫助皮膚從體內及皮膚表層吸得水分，能長時間保水。當玻尿酸吸收水分後，便能使彈力纖維及膠原蛋白處在充滿濕潤的環境中，皮膚因此具有了彈性。
            </h3>
        </div>
    </section>
</template>

<script>
    export default {
        name: "HaB5AquaSpaInfo2"
    }
</script>

<style lang="scss" scoped>
    @import "./variables.scss";

    .info-2 {
        background-color: $background-color;

        .title {
            color: $text-color-light;
        }

        .content {
            color: $text-color-dark;
        }
    }
</style>
